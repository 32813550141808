<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Cria nova Turma"
      :enableClose="false"
    >
      <div class="form-group">
        <label for="ano">Ano Letivo</label>
        <select id="ano" v-model="item.anoSelecionado" class="form-control">
          <option v-for="ano in anos" :key="ano.id" :value="ano.id">
            {{ ano.titulo }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="titulo">Turma</label>
        <input
          id="titulo"
          v-model="item.titulo"
          type="text"
          class="form-control"
        />
      </div>
      <hr class="full-hr" />
      <div class="row">
        <div class="col-sm-12">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-classic"
              id="salvarTurma"
              :disabled="!formIsValid"
              @click.prevent="salvar"
            >
              Salvar
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2"
              @click="showModalCreate = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Turmas</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Turmas
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        id="novaTurma"
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="
                  block__classic
                  table
                  table__classic
                  table-striped
                  table-borderless
                  table-hover
                  text-center
                  mb-4
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Ano</th>
                    <th scope="col">Título</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in filteredRows" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.anoEnsino.titulo)"
                    />
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.titulo)"
                    />
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="editar(index, item)"
                      >
                        <i class="far fa-edit" />
                      </button>
                      <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Swal from "sweetalert2";
import store from "@/store";
import { constantes } from "@/constants";
// import Navigator from '../../../components/anos_turmas/AnosTurmasNavigator.vue';

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
    // Navigator
  },
  data() {
    return {
      showLeftMenu: true,
      showModalCreate: false,
      id: null,
      index: -1,
      item: {
        id: null,
        anoEnsino: null,
        titulo: "",
        anoSelecionado: null,
      },
      items: [],
      filter: "",
      anos: [],
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.titulo.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    formIsValid() {
      if (!this.item.titulo || this.item.anoEnsino == "") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
    this.loadAnos();
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.item = {};
      this.index = -1;
      this.item.cliente = null;
    },
    salvar() {
      this.saveServer();
    },
    deletar(index) {
      console.log(index);
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção dessa turma?",
        text: "Ao excluir a turma, todos os alunos a ela vinculados também serão excluidos!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.$root.$emit("Spinner::show");

            this.$api.put("turma_ensinos/" + index + "/trash", {}).then(() => {
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Turma removida com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.items.splice(index, 1);
              this.loadData();
            });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro no processo!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      // this.items.splice(index, 1);
    },
    editar(index, data) {
      this.index = index;
      this.item = data;
      console.log(index);
      this.item = this.items[index];
      this.item.anoSelecionado = this.item.anoEnsino.id;
      this.showModalCreate = true;
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");
      let filterInstituicao = [];
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$api.get("ano_ensinos").then((response) => {
        console.log(response.data);
        // response.data.forEach((res) => {
        //   if (res.instituicao.id == idInstituicao) {
        //     filterInstituicao.push(res);
        //   }
        // });
        // this.items = response.data;
        // filterInstituicao.forEach((res) => {
        //   if (res.trashAt == null) {
        //     this.anos.push(res);
        //   }
        // });
        // this.anos = response.data;
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.anos.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    loadData() {
      this.$root.$emit("Spinner::show");
      let filterInstituicao = [];
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$api.get("turma_ensinos").then((response) => {
        console.log(response.data);
        if (idInstituicao == 0) {
          this.items = response.data;
        } else {
          response.data.forEach((res) => {
            console.log(res);
            if (res.instituicao != null) {
              if (res.trashAt == null && res.instituicao.id == idInstituicao)
                filterInstituicao.push(res);
            }
          });
          this.items = filterInstituicao;
        }
        // this.items = response.data;
        this.items = filterInstituicao.sort();
        this.$root.$emit("Spinner::hide");
      });
    },
    saveServer() {
      this.$root.$emit("Spinner::show");
      this.item.anoEnsino = this.item.anoSelecionado;
      this.showModalCreate = false;
      this.item.instituicao = parseInt(
        localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO)
      );
      if (this.index >= 0) {
        this.$api
          .put("turma_ensinos/" + this.item.id, this.item)
          .then(() => {
            this.loadData();

            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Turma alterada com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {});
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      } else {
        this.$api
          .post("turma_ensinos", this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Turma cadastrada com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              setTimeout(function () {
                location.reload();
              }, 2000);
              this.showModalCreate = false;
            });
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      }
    },
  },
};
</script>

<style></style>
